require('./bootstrap');

$(document).ready(function() {
    $("#login").validate({
        rules: {
            email: {
                required: true,
                email: true
            },
            password: {
                required: true,
                minlength: 8
            }
        },
        messages: {
            email: {
                required: __("Email is required"),
                email: __("Email is invalid")
            },
            password: {
                required: __("Password is required"),
                minlength: __("Password must be at least 8 characters.")
            }
        },
        errorPlacement: function(error, element) {
            if (element.attr("name") === "email" || element.attr("name") === "password") {
                error.appendTo(element.parent("div").find(".error-holder"));
            } else {
                error.insertAfter(element);
            }
        }
    });

    $("#forgot_password").validate({
        rules: {
            email: {
                required: true,
                email: true
            }
        },
        messages: {
            email: {
                required: __("Email is required"),
                email: __("Email is invalid"),
            }
        },
        errorPlacement: function(error, element) {
            if (element.attr("name") === "email") {
                error.appendTo(element.parent("div").find(".error-holder"));
            } else {
                error.insertAfter(element);
            }
        }
    });

    $("#reset_password").validate({
        rules: {
            email: {
                required: true,
                email: true
            },
            password: {
                required: true,
                minlength: 8
            },
            password_confirmation: {
                required: true,
                minlength: 8,
                equalTo: ".password"
            }
        },
        messages: {
            email: {
                required: __("Email is required"),
                email: __("Email is invalid")
            },
            password: {
                required: __("Password is required"),
                minlength: __("Password must be at least 8 characters")
            },
            password_confirmation: {
                required: __("Password (again) is required"),
                minlength: __("Password must be at least 8 characters"),
                equalTo: __("Password does not match")
            }
        },
        errorPlacement: function(error, element) {
            if (element.attr("name") === "password_confirmation" || element.attr("name") === "password") {
                error.appendTo(element.parent("div").find(".error-holder"));
            } else {
                error.insertAfter(element);
            }
        }
    });
});
